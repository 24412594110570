import React, { useState, useContext } from 'react';
import '../../../utils/date';
import FilterIcon from '../../../icons/FilterIcon';
import PlusIcon from '../../../icons/PlusIcon';
import DateMonthPicker from '../../../components/common/DateMonthPicker';
import StatusDropdown from './StatusDropdown';
import { Modal, FormControl, InputLabel, Select } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import { AddFilePanelBackoffice } from '../Autoral/components/Panels';
import { AddFilePanelCompanies } from '../Conexo/components/Panels';

export const defaultFilters = {
  status: null,
  date: null,
  source: null,
};

const ActionBar = (props) => {
  const [showFilters, showHideFilters] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    props.onFilterChange(newFilters);
  };

  const onStatusChangeHandler = (e) => {
    const status = e.target.value;
    updateFilters({ ...filters, status: status });
  };
  const onDateChange = (date) => {
    updateFilters({ ...filters, date: date });
  };
  const onSourceChangeHandler = (source) => {
    updateFilters({ ...filters, source: source });
  };
  const onFileSaveHandler = (success) => {
    if (success) setShowForm(false); // do not hide the form if there was an error uploading the file
    props.onSave(success);
  };

  return (
    <>
      <div className="page-actionbar-container">
        <div
          className="page-actionbar-item action-btn"
          onClick={() => showHideFilters(!showFilters)}
        >
          <FilterIcon />
        </div>
        {props.isBackoffice && props.hasRole(['PAYMENT_FILE_WORKS_WRITE']) ? (
          <div
            className="page-actionbar-item action-btn"
            onClick={() => {
              setShowForm(true);
            }}
          >
            <PlusIcon />
          </div>
        ) : null}

        {props.isCompanies && props.hasRole(['PAYMENT_FILE_TRACKS_WRITE']) ? (
          <div
            className="page-actionbar-item action-btn"
            onClick={() => {
              setShowForm(true);
            }}
          >
            <PlusIcon />
          </div>
        ) : null}
      </div>
      {showFilters ? (
        <div className="page-filter-bar">
          <DateMonthPicker
            className="filter-date"
            label="Data"
            onChange={onDateChange}
          />
          <StatusDropdown onStatusChange={onStatusChangeHandler} />
          <FormControl margin="normal" style={{ width: '100%' }}>
            <InputLabel style={{ padding: '0 10px' }}> Fonte </InputLabel>
            <Select
              native
              onChange={(e) => {
                onSourceChangeHandler(e.target.value);
              }}
              variant="outlined"
            >
              {props.isBackoffice ? (
                <>
                  <option aria-label="None" value="" />
                  <option value={1}>Youtube</option>
                  <option value={2}>Backoffice</option>
                  <option value={3}>Deezer BMAT</option>
                </>
              ) : (
                <>
                  <option aria-label="None" value="" />
                  <option value={1}>Believe</option>
                  <option value={2}>TheOrchard</option>
                  <option value={3}>OneRPM</option>f{' '}
                  <option value={4}>Ingrooves</option>
                  <option value={5}>Altafonte</option>
                  <option value={6}>FUGA</option>
                  <option value={7}>Ingrooves USA</option>
                  <option value={8}>Universal</option>
                  <option value={9}>SomLivre</option>
                  <option value={10}>Symphonic</option>
                  <option value={11}>CmdShift</option>
                  <option value={12}>Universal 2</option>
                  <option value={13}>Believe 2</option>
                </>
              )}
            </Select>
          </FormControl>
        </div>
      ) : null}

      {props.isBackoffice ? (
        /* Modal dialog for adding new report file */
        <Modal
          open={showForm}
          onClose={() => {
            setShowForm(false);
          }}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <div className="modal-container">
            <AddFilePanelBackoffice onSave={onFileSaveHandler} />
          </div>
        </Modal>
      ) : null}
      {props.isCompanies ? (
        /* Modal dialog for adding new report file */
        <Modal
          open={showForm}
          onClose={() => {
            setShowForm(false);
          }}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <div className="modal-container add-file">
            <AddFilePanelCompanies onSave={onFileSaveHandler} />
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default ActionBar;
